body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* 重写 RangePicker 组件开始和截止选中单元格的样式 */
.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #266d33 !important;
    color: #4bff50 !important;
}

/* 重写单元格样式后 before 不存在了，只能再写一遍 */
.ant-picker-cell-range-start .ant-picker-cell-inner::before,
.ant-picker-cell-range-end .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1;
    border: 1px solid #4bff50;
    border-radius: 2px;
    content: "";
}

/* 重写 tooltip 样式 */
.ant-tooltip-inner {
    border: 1px solid #45ffde !important;
}

.ant-tooltip-arrow::before {
    background: #45ffde !important;
}

/* 文本使用 primary 颜色但是不是 link */
.primary-text {
    color: #45ffde;
}

.siteListDrawer {
    /* 模糊背景 */
    backdrop-filter: blur(10px);
}
